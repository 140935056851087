import ButtonLink from '@components/ButtonLink';
import { Colors, Text, Title } from '@components/global.styles';
import * as React from 'react';
import { Fragment } from 'react';
import { Row } from 'shards-react';
import { Containe, Content } from './styles';

interface IntroductionProps {
  name: string;
}

const Introduction: React.FC<IntroductionProps> = ({ name }) => {
  return (
    <Fragment>
      <Containe>
        <Row>
          <Content>
            <Text style={{ fontSize: '20px' }} colors={Colors.pink}>
              Bine ați venit pe platforma noastră!
            </Text>
            <Title
              className="op1 big-text"
              style={{ marginTop: '2rem' }}
              colors={Colors.gray}
            >
              {name}.
            </Title>
            <Title
              className="big-text"
              style={{ marginTop: '3rem', marginBottom: '3rem', opacity: 0.7 }}
              colors={Colors.light_gray}
            >
              Un podcast creștin
            </Title>
            <Text className="small-text" colors={Colors.gray}>
              Scopul nostru este ca prin episoade scurte să discutăm despre
              diverse teme de actualitate cu care fiecare din noi fie s-a
              confruntat, se confruntă, sau se va confrunta. Temele pe care le
              discutăm sunt tratate în raport cu Dumnezeu și încercăm ca
              răspunsurile pe care le oferim să ne ajute la creșterea și
              devoltarea noastră ca și copii ai Lui!
            </Text>
            <br />
            <div className="content-button01">
              <ButtonLink
                title="Pune o întrebare"
                classes="btn01"
                size="lg"
                link="/question"
              />
              <ButtonLink
                title="Ascultă online"
                classes="btn01"
                size="lg"
                link="/listen"
              />
            </div>
          </Content>
        </Row>
      </Containe>
    </Fragment>
  );
};

export default Introduction;
